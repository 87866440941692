<template>
  <div class="flex-container">
    <div class="container-content-left">
      <div class="d-block">
        <div class="d-flex">
          <b-form-input
            class="contact-input mr-2"
            id="Nombre"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            placeholder="Nombre y Apellido"
          ></b-form-input>
          <b-form-input
            class="contact-input"
            id="Email"
            v-model="$v.form.email.$model"
            :state="validateState('email')"
            placeholder="Email"
          ></b-form-input>
        </div>
        <div class="d-flex">
          <b-form-input
            class="contact-input mr-2"
            id="Cargo"
            v-model="$v.form.position.$model"
            :state="validateState('position')"
            placeholder="Cargo"
          ></b-form-input>
          <b-form-input
            class="contact-input"
            id="Institución"
            v-model="$v.form.institution.$model"
            :state="validateState('institution')"
            placeholder="Institución"
          ></b-form-input>
        </div>
        <div>
          <div class="d-flex">
            <b-form-input
              class="contact-input mr-2"
              id="País"
              v-model="$v.form.country.$model"
              :state="validateState('country')"
              placeholder="País"
            ></b-form-input>
            <b-form-input
              class="contact-input"
              id="Teléfono"
              v-model="$v.form.phone.$model"
              :state="validateState('phone')"
              placeholder="Teléfono"
            ></b-form-input>
          </div>
        </div>
        <div>
          <b-form-textarea
            class="contact-input mb-3"
            id="Mensaje"
            v-model="$v.form.message.$model"
            :state="validateState('message')"
            placeholder="Mensaje"
            rows="4"
            max-rows="6"
          ></b-form-textarea>
        </div>
        <div>
          <b-button class="contact-button" @click="send">ENVIAR</b-button>
          <!-- <form @submit="send">
            <vue-recaptcha @verify="onRecaptchaVerified"></vue-recaptcha>
            <b-button type="submit" class="contact-button">ENVIAR</b-button>
          </form> -->
        </div>
      </div>
    </div>
    <div class="container-content-right-background">
      <div class="container-content-right">
        <div class="content-right-1 font-text">¡CONTÁCTANOS! ;)</div>
        <div class="content-right-2 font-text">
          Si está buscando una solución integral para la construcción y
          evaluación de currículos educativos, no busque más allá de Key
          Learning. Contáctenos hoy mismo para obtener más información sobre
          cómo podemos ayudarlo/a a mejorar la calidad de la educación.
        </div>
        <div class="content-right-3 font-text">
          <a
            style="color: #f5f5f5 !important"
            href="mailto:admin@klearning.cl?Subject=Deseo%20solicitar%20un%20demo%20de%20la%20plataforma."
            >admin@klearning.cl</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
// import { VueReCaptcha } from "vue-recaptcha-v3";
// Vue.use(VueReCaptcha, {
//   siteKey: "6LdG-fQmAAAAAIwcCSG13BNM5oVsxYZmMFb4qNI8",
//   loaderOptions: {
//     useRecaptchaNet: true,
//   },
// });
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "ContactForm",
  mixins: [validationMixin],
  components: {
    // "vue-recaptcha": VueReCaptcha,
  },
  props: {},
  data() {
    return {
      form: {
        name: "",
        email: "",
        position: "",
        institution: "",
        country: "",
        phone: "",
        message: "",
      },
    };
  },
  validations: {
    form: {
      name: { required },
      email: {
        required,
        isValid() {
          if (
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
              this.form.email
            )
          ) {
            return true;
          } else return false;
        },
      },
      position: { required },
      institution: { required },
      country: { required },
      phone: {},
      message: { required },
    },
  },
  computed: {},
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.form[key];
      return $dirty ? !$error : null;
    },
    // onRecaptchaVerified(response) {
    //   // Se llama cuando ReCaptcha se verifica correctamente
    //   console.log("ReCaptcha verificado:", response);
    // },
    send() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;
      this.$restful.Post(`/common/contact-data/`, this.form).then(() => {
        this.$swal.fire({
          type: "success",
          title: `¡¡¡Mensaje enviado!!!`,
          showConfirmButton: false,
          timer: 2500,
        });
        this.form.name = "";
        this.form.email = "";
        this.form.position = "";
        this.form.institution = "";
        this.form.country = "";
        this.form.phone = "";
        this.form.message = "";
      });
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.flex-container {
  display: flex;
  height: 416px;
  background: #212121;
}
.font-text {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  line-height: 130%;
}
.form-control {
  color: white;
}
.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #f5f5f5;
  font-style: Light;
  opacity: 0.8;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #f5f5f5;
  font-style: Light;
  opacity: 0.8;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #f5f5f5;
  font-style: Light;
  opacity: 0.8;
}
.contact-button {
  background: #01adb7;
  border-radius: 33px;
  height: 4rem;
  width: 100%;
}
.contact-input {
  box-sizing: border-box;
  border: 0.5px solid #484848;
  border-radius: 5px;
  background-color: rgb(0, 0, 0);
}
.container-content-left {
  display: block;
  width: 50%;
  margin: 10%;
  align-self: center;
  text-align: -webkit-center;
}
.container-content-right-background {
  background-image: url("../../assets/new-page/KL_BACKGROUND.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  width: 50%;
  height: 100%;
}
.container-content-right {
  text-align: -webkit-center;
  display: block;
  align-self: center;
}
.content-right-1 {
  /* font-family: 'Source Sans Pro', sans-serif;
  font-style: normal; */
  font-weight: 600;
  font-size: 48px;
  /* line-height: 130%; */
  /* or 62px */

  color: #f5f5f5;
}
.content-right-2 {
  width: 55%;
  /* font-family: 'Source Sans Pro', sans-serif;
  font-style: normal; */
  font-weight: 300;
  font-size: 16px;
  /* line-height: 120%; */
  /* or 19px */
  color: #f5f5f5;
  margin-top: 20px;
  text-align: justify;
}
.content-right-3 {
  margin-top: 20px;
  width: 55%;
  text-align: left;
  /* font-family: 'Source Sans Pro', sans-serif;
  font-style: normal; */
  font-weight: 700;
  font-size: 20px;
  /* line-height: 120%; */
  /* or 24px */

  color: #f5f5f5;
}
@media (max-width: 1122px) {
  .flex-container {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    height: auto;
    background: #212121;
  }
  .content-right-1 {
    font-size: 30px;
  }
  .content-right-2 {
    width: 70%;
  }
  .container-content-right-background {
    background: #212121;
    width: 100%;
  }
  .container-content-left {
    width: 100%;
    margin: 0%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .container-content-right {
    margin-top: 20px;
    text-align: -webkit-center;
    display: block;
    align-self: center;
  }
}
</style>